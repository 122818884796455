import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ButtonLink from "../components/button-link"
import PageTitle from "../components/page-title"
import ImageSection from "../components/image-section"
import Seo from "../components/seo"

const CasesPage = () => {
  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Over Ons' }}/>
      
      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <PageTitle data={{
        title: 'Over Ons',
        description: 'Alles over Delta Futurum',
        image: 'https://cms.deltafuturum.nl/uploads/medium_about_8f83cfbdc6.jpg'
      }}>
        <div className="grid grid-cols-9">
          <h2 className="md:col-start-2 col-span-9 md:col-span-6 text-brand-blue font-bold">
            Wij bouwen <span className="text-brand-green">doeltreffende</span><br/><span className="text-brand-orange">maatwerk</span> websites
          </h2>
        </div>
      </PageTitle>

      {/* Section */}
      <ImageSection image="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1415&q=80">
        <h1>Wat we doen</h1>
        <p>
          Wij helpen bedrijven met het verbeteren van hun online positie. Dat doen we door het bouwen van op maat gemaakte websites. Vanuit de wensen van de klant ontwerpen we een website die de perfecte ervaring biedt voor de bezoeker. 
          <br /><br />
          We ontwikkelen de website met efficiënte custom code. Geen overbodige plugins of verouderde frameworks, maar de modernste en snelste technologiën voor op het web. Met als resultaat, een razensnelle en converterende website.
          <br /><br />
          Tijdens het bouwen van een website doen we aan zoekmachineoptimalisatie (SEO). We stellen alles in zodat je zo hoog mogelijk gevonden wordt.
        </p>
      </ImageSection>

      {/* Section */}
      <ImageSection reverse transparent image="https://cms.deltafuturum.nl/uploads/medium_profiel_a6848accff.jpg">
        <h1>Wie zit er achter?</h1>
        <p>
          Je bent waarschijnlijk op zoek naar het gezicht achter alles wat je hier ziet. Delta Futurum is opgericht door Jim van IJcken. Begin 2022 opende hij de deuren van Delta Futurum. Met als doel: het helpen van bedrijven met hun online positie door het bouwen van doeltreffende websites.
          <br /><br />
          Wil je kennismaken? Stuur dan een mailtje of bel gerust.
        </p>
        <ButtonLink to="/contact">Neem contact op</ButtonLink>
      </ImageSection>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default CasesPage